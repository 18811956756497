import React from "react";
import { graphql } from "gatsby";
import Page from "./Page";
import Section from "../components/UI/Section";
import Container from "../components/UI/Container";
import Content, { HTMLContent } from "../components/Content";
import PropTypes from "prop-types";

export const BlogRawTemplate = ({ content, contentComponent }) => {
  const PostContent = contentComponent || Content;
  return (
    <Section>
      <Container>
        <PostContent content={content} />
      </Container>
    </Section>
  );
};

const BlogTemplate = ({ data }) => {
  const infos = {
    title: data.markdownRemark.frontmatter.title,
    description: data.markdownRemark.frontmatter.description
  };
  return (
    <Page title={infos.title} description={infos.description}>
      <BlogRawTemplate content={data.markdownRemark.html} contentComponent={HTMLContent} />
    </Page>
  );
};

BlogTemplate.propTypes = {
  data: PropTypes.node
};
BlogRawTemplate.propTypes = {
  content: PropTypes.node,
  contentComponent: PropTypes.func
};
export default BlogTemplate;

export const pageQuery = graphql`
  query BlogByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        date
        tags
      }
      html
    }
  }
`;
